(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name events.vehicles.edit.controller:VehiclesEditCtrl
   *
   * @description
   *
   */
  angular
    .module('events.vehicles.edit')
    .controller('VehiclesEditCtrl', VehiclesEditCtrl);

  function VehiclesEditCtrl($state, $stateParams, $mdDialog, $filter, Restangular, event, sections) {
    var vm = this;

    vm.event = event.data;
    vm.sections = sections;
    vm.vehicle = {};

    vm.created_at = '';
    vm.updated_at = '';
    vm.isEditing = $state.is('events.vehicles.edit');
    vm.errors = [];

    vm.submit = submit;
    vm.deleteVehicle = deleteVehicle;
    vm.showConfirmDelete = showConfirmDelete;
    vm.upperCaseNumber = upperCaseNumber;
    vm.upperCaseRego = upperCaseRego;

    // Set default section
    for (var i = 0; i < sections.length; i++) {
      if (sections[i].code == '--') {
        vm.vehicle.section_id = sections[i].id;
      }
    }

    if (vm.isEditing) {
      Restangular.one('vehicles', $stateParams.vehicleId).get().then(function (response) {

        response.data.donation = $filter('dollars')(response.data.donation);
        response.data.entry_fee = $filter('dollars')(response.data.entry_fee);

        vm.vehicle = response.data;
        vm.last_front_end_check = (vm.vehicle.last_front_end_check === '0001-01-01T00:00:00Z') ? null : new Date(vm.vehicle.last_front_end_check);
        vm.created_at = moment(vm.vehicle.created_at).format('Do MMM YYYY');
        vm.updated_at = moment(vm.vehicle.updated_at).format('Do MMM YYYY');
      });
    } else {
      vm.vehicle = {
        event_id: vm.event.id,
        last_front_end_check: null,
        make: '',
        rego: '',
        section_id: '',
        team: '',
        type: '',
        vehicle_model: '',
        year: '',
        number: '',
        donation: $filter('dollars')(vm.event.min_donation),
        entry_fee: $filter('dollars')(vm.event.entry_fee),
        notes: ''
      }
    }

    function submit() {
      vm.vehicle.year = parseInt(vm.vehicle.year, 10);

      var data = angular.copy(vm.vehicle);
      data.last_front_end_check = vm.last_front_end_check;

      // Dollars to cents
      data.donation = $filter('cents')(vm.vehicle.donation);
      data.entry_fee = $filter('cents')(vm.vehicle.entry_fee);

      if (vm.isEditing) {
        Restangular.all('vehicles').customPUT(data, vm.vehicle.id).then(submitSuccess, submitFailure);
      } else {
        Restangular.all('vehicles').post(data).then(submitSuccess, submitFailure);
      }
    }

    function submitSuccess(response) {
      var id = vm.isEditing ? vm.vehicle.id : response.data.id;
      $state.go('events.vehicles.list', {eventId: vm.event.id});
    }

    function submitFailure(response) {
      console.log('vehicle submit failed', response.data.errors);
      vm.errors = response.data.errors;
    }

    function deleteVehicle() {
      Restangular.one('vehicles', vm.vehicle.id).remove().then(deleteSuccess, deleteFailure);
    }

    function deleteSuccess() {
      $state.go('events.vehicles.list', {eventId: vm.event.id});
    }

    function deleteFailure(response) {
      console.log('failed delete', response);
      alert('Delete vehicle failed');
    }

    function showConfirmDelete(ev) {
      var confirm = $mdDialog.confirm()
        .title('Delete Vehicle')
        .textContent('Do you really want to delete vehicle ' + vm.vehicle.number + '?')
        .ariaLabel('Confirm delete vehicle')
        .targetEvent(ev)
        .ok('Delete')
        .cancel('Cancel');
      $mdDialog.show(confirm).then(function () {
        deleteVehicle();
      });
    }

    function upperCaseNumber() {
      if (vm.vehicle.number) {
        vm.vehicle.number = vm.vehicle.number.toUpperCase();
      }
    }

    function upperCaseRego() {
      if (vm.vehicle.rego) {
        vm.vehicle.rego = vm.vehicle.rego.toUpperCase();
      }
    }
  }
}());
